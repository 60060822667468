<template>
  <div :class="{'full-width': $vuetify.breakpoint.smAndDown}">
    <div class="d-flex flex-column align-center justify-center" v-if="$vuetify.breakpoint.smAndDown">
      <div class="black--text text-body-2 mb-2">Xin chào cha mẹ học sinh</div>
      <v-btn
        color="#265FC9"
        class="text-none white--text"
        v-if="isOpen"
        :class="{'btn-text': $vuetify.breakpoint.mdAndUp}"
        @click="onRegisterPhone"
        dense
        large
      >
        Đăng ký nhận thông tin tuyển sinh
      </v-btn>
    </div>
    <div v-else class="d-flex align-center">
      <div class="black--text text-body-2 mr-4">Xin chào cha mẹ học sinh</div>
      <v-btn
        color="#265FC9"
        class="text-none white--text"
        v-if="isOpen"
        :class="{'btn-text': $vuetify.breakpoint.mdAndUp}"
        @click="onRegisterPhone"
        dense
        large
      >
        Đăng ký nhận thông tin tuyển sinh
      </v-btn>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    isOpen: Boolean
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated', 'user', 'isConfirmedOTP'])
  },
  methods: {
    ...mapActions('layout', [
      'setSignUpDialog',
      'setSignInDialog',
      'setAllDialogClose',
      'setConfirmSignupDialog',
      'setPhoneRegisterDialog'
    ]),
    // onSigninClick() {
    //   this.setAllDialogClose();
    //   this.setSignInDialog(true);
    // },
    // onSignupClick() {
    //   this.setAllDialogClose();
    //   if (this.isAuthenticated && this.user && !this.isConfirmedOTP)
    //     this.setConfirmSignupDialog(true);
    //   else this.setSignUpDialog(true);
    // },
    onRegisterPhone() {
      this.setAllDialogClose()
      // if (this.isAuthenticated && this.user && !this.isConfirmedOTP) this.setConfirmSignupDialog(true)
      this.setPhoneRegisterDialog(true)
    }
  }
}
</script>
<style scoped>
.full-width {
  width: 100%;
}
.btn-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
</style>
